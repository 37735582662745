import styled from 'styled-components';
import { IonButton, IonRow } from '@ionic/react';

export const Container = styled.div`
  padding: 30px;
  border-bottom: 1px solid #e0e0e0;
`;

export const NotesContainer = styled(IonRow)`
  padding: 10px;
  border: 0.5px solid var(--ion-color-light-tint);

`;

export const NotesInput = styled.input.attrs({
  placeholder: `Write a note for this review descriptor here.`,
  maxLength: 256
})`
  width: 100%;
  padding: 20px;
  border-radius: 10px;
`;

export const NotesSize = styled.div`
  width: 100%;
  padding: 10px 10px 0 0;

  display: flex;
  justify-content: end;

  color: var(--ion-color-light-tint);
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 600px;

  &::first-child {
    border-radius: 10px !important;
  }import { RouteProps } from 'react-router-dom';

`;

interface ButtomRadius {
  isRadiusLeft?: boolean;
  isRadiusRight?: boolean;
}

export const Button = styled(IonButton)<ButtomRadius>`
  flex: 1;
  margin: 0;
  text-transform: uppercase;
  font-family: 'Montserrat', serif;

  ::part(native) {
    border-radius: ${props => props.isRadiusLeft ? '10px' : '0'} ${props => props.isRadiusRight ? '10px' : '0'}
    ${props => props.isRadiusRight ? '10px' : '0'} ${props => props.isRadiusLeft ? '10px' : '0'}
    ;
  }
`;

export const SpanInfo = styled.span`
  font-size: 10px;
  font-weight: 700;
  text-align: end;
  text-transform: uppercase;
`;


