import styled from "styled-components";

export const Centered = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`;


export const PromoCodesListcontainer = styled.div.attrs({
  className: 'ion-padding'
})`
  display: flex;
  flex-direction: column;

  margin: 0 10px;
  gap: 25px;
`;
