import React from 'react';
import styled from 'styled-components';
import { Controller, Control } from 'react-hook-form';

const InputStyled = styled.input`
  width: 100%;
  padding: 1.2em 1.5em 1.2em 1.5em;
  background-color: var(--input-background);
  color: var(--input-text-color);
  border-radius: var(--input-border-radius);

  @media (max-width: 765px) {
    font-size: 12px;
  }
`;

type InputProps = {
  name: string;
  type:
    | 'number'
    | 'time'
    | 'text'
    | 'date'
    | 'email'
    | 'password'
    | 'search'
    | 'tel'
    | 'url'
    | undefined;
  control: Control;
  rules?: any;
  placeholder?: string;
  onChange?: any;
  onBlur?: any;
  inputClass?: string;
  autocapitalize?: string;
  value?: any;
};

const TextFieldInputAutoComplete: React.FC<InputProps> = ({
  name,
  type,
  control,
  rules,
  placeholder,
  onChange: onChangeComponentProp,
  onBlur: onBlurComponentProp,
  inputClass,
  autocapitalize,
  value
}) => {

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={value}
      render={({ field: { onChange, onBlur, value } }) => {
        return (
          <InputStyled
            id={name}
            autoCapitalize={autocapitalize}
            className={inputClass || 'login-default-input'}
            type={type}
            value={value}
            onChange={(e) => {
              if(onChangeComponentProp) onChangeComponentProp(e);
              onChange(e.target.value);
            }}
            onBlur={() => {
              if (onBlurComponentProp) onBlurComponentProp();
              onBlur();
            }}
            placeholder={placeholder}
          />
        );
      }}
    />
  );
};

export default TextFieldInputAutoComplete;
