import React, { useState, useEffect } from 'react';
import plinkLogo from '../../assets/plink_logo.png';
import backgroundImg from '../../assets/background_light.svg';
import { useHistory } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import TextFieldInputAutoComplete from '../../components/form/TextFieldInputAutoComplete';
import {
  IonAlert,
  IonButton,
  IonCol,
  IonPage,
  IonRow,
  IonSpinner,
  IonText
} from '@ionic/react';
import styled from 'styled-components';
import { useLogin } from '../../features/auth/auth-resolver';
import { FetchError } from '../../features/fetch/fetch-types';

type FormValues = {
  email: string;
  password: string;
};

const Container = styled(IonPage).attrs({
  className: 'ion-padding'
})`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  height: 100%;

  background: url('${backgroundImg}') no-repeat bottom;
`;

const LoginInfo = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 90%;
  padding-left: 100px;

  h1 {
    font-family: 'Montserrat', serif;
    letter-spacing: 5px;
    font-size: 16px;
  }

  @media (max-width: 765px) {
    width: 100%;
    padding-left: 0;

    h1 {
      width: 60%;
      text-align: center;
    }
  }
`;

const BlueInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;

  background-color: var(--plink-blue);
  width: 60%;
  height: 90%;
  margin-right: 1%;
  border-radius: 10px;

  img {
    width: 250px;
  }

  span {
    font-family: 'Montserrat', serif;
    letter-spacing: 5px;
    cursor: default;
  }


  @media (max-width: 765px) {
    display: none;
  }
`;

const StyledForm = styled.form`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 70%;
`;

const StyledButton = styled(IonButton)`
  width: 12em;
  height: 3.5em;
  font-family: 'Montserrat', serif;
`;

const InputErrorText = styled(IonText)`
  align-self: start;
`;

const CopyrightFooter = styled.footer`
  display: flex;
  justify-content: center;
  font-size: 10px;

  position: absolute;
  bottom: 0;
  left: 50%;
  right: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
`;

const Login: React.FC = () => {
  const [errors, setErrors] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [{ isLoading }, doLogin] = useLogin();

  const history = useHistory();

  const loginSchema = yup.object().shape({
    email: yup.string().required('Required'),
    password: yup.string().required('Required')
  });

  const {
    control,
    handleSubmit,
    formState: { errors: formErrors },
  } = useForm({
    resolver: yupResolver(loginSchema)
  });

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    const logInRes = await doLogin({
      username: data.email,
      password: data.password
    });

    if (logInRes && (logInRes as FetchError).errorCode) {
      setErrorMessage(logInRes.message);
      setErrors(true);
    } else {
      history.push('/businesses');
    }
  };


  const removeErrors = () => {
    setErrors(false);
  }

  return (
    <>
    <Container>

      <LoginInfo>
        <h1>Please, enter your Credentials to Login.</h1>
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <TextFieldInputAutoComplete
            name="email"
            type="email"
            control={control}
            placeholder="Email"
          />
          <InputErrorText color="danger">
            {formErrors.email && formErrors.email.message}
          </InputErrorText>
          <TextFieldInputAutoComplete
            name="password"
            type="password"
            control={control}
            placeholder="Password"
          />
          <InputErrorText color="danger">
            {formErrors.password && formErrors.password.message}
          </InputErrorText>
          <IonRow>
            <IonCol className="ion-text-center">
              <StyledButton type="submit" expand="block" disabled={isLoading}>
                LOG IN
              </StyledButton>
            </IonCol>
          </IonRow>
        </StyledForm>
        <IonAlert
          isOpen={errors}
          cssClass="modal-dark-mode"
          header="Error logging in"
          message={errorMessage}
          buttons={['OK']}
          onDidDismiss={removeErrors}
        />
      </LoginInfo>
      <BlueInfo>
        <img src={plinkLogo} alt="Plink Logo" />
        <span>Admin Tool</span>
      </BlueInfo>

    </Container>
    <CopyrightFooter>© Copyright 2025 Plink, Inc. All Rights Reserved.</CopyrightFooter>
    </>
  );
};

export default Login;
