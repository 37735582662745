import { useGet, usePatch } from '../fetch/fetch-resolver';
import {
  setFinancialInstitutions,
  setSelectedFinancialInstitution,
  setResolutionTypes,
  setAllFinancialInstitutions
} from './financial-institutions-actions';
import {
  FinancialInstitutions,
  FinancialInstitutionWithDetails,
  } from './financial-institutions-types';
import { PaginatedResponse } from '../../types/api';
import { UseGetOptions } from '../fetch/fetch-types';

export const useGetFinancialInstitutions = (
  options = {} as Partial<UseGetOptions>
) => {
  return useGet<PaginatedResponse<FinancialInstitutions[]>>({
    url: 'banks',
    version: 'v2',
    action: setFinancialInstitutions,
    selector: (state) => state.financialInstitutions.list,
    initialParams: {
      page: 1,
      limit: 30
    },
    ...options
  });
};

export const useGetFinancialInstitutionsAll = (
  options = {} as Partial<UseGetOptions>
) => {
  return useGet<FinancialInstitutions[]>({
    url: 'banks/all',
    version: 'v2',
    action: setAllFinancialInstitutions,
    selector: (state) => state.financialInstitutions.listAll,
    ...options
  });
};

export const useGetFinancialInstitutionsInfo = (
  { id }: { id: number },
) => {
  return useGet<FinancialInstitutionWithDetails>({
    url: `banks/${id}`,
    version: 'v2',
    action: setSelectedFinancialInstitution,
    selector: (state) => id === state.financialInstitutions.selected?.id
      ? state.financialInstitutions.selected
      : undefined
  });
};

export const useGetResolutionsType = () => {
  return useGet({
    url: 'banks/resolution-types',
    version: 'v2',
    action: setResolutionTypes,
    selector: (state) => state.financialInstitutions.resolutionTypes,
  })
}

export const usePatchFinancialIntitutionInfo = () => {
  return usePatch({
    url: `banks`,
    version: 'v2',
  })
}
