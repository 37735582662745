import { useState } from 'react';
import { v4 as uuid } from 'uuid';
import styled from 'styled-components';

import { IonList } from '@ionic/react'
import {
  Consumer,
  Panel,
  PanelOptions
} from '../../../../features/consumers/consumers-types';

import Item from './item'

// ---------------------------------
const ConsumersList = styled(IonList)`
  background: transparent;

  .customer-item {
    margin: 10px 0 20px 0;
  }
`;

type ConsumerItemsProps = {
  consumers: Consumer[]
  changePanel: (newPanel: Panel) => void
  panel: Panel
  [key: string]: any
}

export default function ConsumerItems(
  {consumers, changePanel, panel, ...rest}: ConsumerItemsProps
){

  return <ConsumersList>
    {
      consumers.map((c, i) => {
        return <Item key={c?.consumerId ? c.consumerId : uuid()} consumer={c}/>
      })
    }
  </ConsumersList>
}
