import { IonSearchbar } from "@ionic/react";
import styled from "styled-components";

export const SearchContainer = styled.div.attrs({
  className: 'ion-padding'
})`
  margin-bottom: -20px;
`;

export const SearchBar = styled(IonSearchbar).attrs({
  showClearButton: 'never'
})`
  margin-bottom: auto;
`;
