import React from "react";
import { BusinessEntity } from "../../../../../features/business-entities/business-entities-types";
import {
  Item,
  IconHeader,
  Column,
  ItemText,
  TextLabel,
} from '../../../../../components/StylesListing';

// Icons
import { IonIcon } from '@ionic/react';
import { openOutline } from 'ionicons/icons';

interface BusinessEntityItemProps {
  businessEntity: BusinessEntity;
}

export const BusinessEntityItem: React.FC<BusinessEntityItemProps> = ({
  businessEntity: {
    id,
    merchantName,
    legalCorporateName,
    dbaName,
    streetAddress,
    city,
    state,
  },
}) => {
  return (
    <>
      <Item
        data-testid="business-entity-item"
        button
        routerLink={`/business-entities/${id}`}
      >
        <IconHeader>
          <IonIcon icon={openOutline} />
        </IconHeader>
        <Column>
          <ItemText data-testid="business-entity-item-id" isTitle>
            <TextLabel>ID:</TextLabel> {id}
          </ItemText>
          <ItemText data-testid="business-entity-item-merchant-name" isTitle>
            <TextLabel>Merchant Name:</TextLabel> {merchantName || 'N/A'}
          </ItemText>
          <ItemText data-testid="business-entity-item-legal-corp-name">
            <TextLabel>Legal Corporate Name:</TextLabel> {legalCorporateName || 'N/A'}
          </ItemText>
          <ItemText data-testid="business-entity-item-dba-name">
            <TextLabel>DBA Name:</TextLabel> {dbaName || 'N/A'}
          </ItemText>
          {(!!streetAddress || !!city || !!state) && (
            <ItemText data-testid="business-entity-item-address">
              <TextLabel>Address:</TextLabel> {streetAddress ? streetAddress + ',' : ''} {city} {state}
            </ItemText>
          )}
        </Column>
      </Item>
    </>
  )
};
