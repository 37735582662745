import { IonPage } from "@ionic/react";
import styled from "styled-components";

export const IonContainer = styled(IonPage)``;

export const Centered = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const BusinessesListcontainer = styled.div.attrs({
  className: 'ion-padding'
})`
  display: flex;
  flex-direction: column;

  margin: 0 10px;
  gap: 25px;
`;
