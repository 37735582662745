import {
  IonAlert,
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonSpinner,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { clearBusinesses, setBusinessesSearchQuery } from '../../../features/businesses/businesses-actions';
import { useGetBusinesses } from '../../../features/businesses/businesses-resolver';
import { useAppState } from '../../../shared/AppContext';
import { usePaginationMode } from '../../../utils/hooks';
import { BusinessItem } from './components/BusinessItem';
import InfiniteScroll from '../../../components/InfiniteScroll';
import PaginationArrows from '../../../components/PaginationArrows';
import { Centered, BusinessesListcontainer, IonContainer } from './styles';

// SearchBarInfo
import {
  SearchContainer,
  SearchBar,
} from '../../../components/SearchBarContainerAndInfo';

const Businesses: React.FC = () => {
  const history = useHistory();

  const {
    dispatch: appStateDispatch,
    state: appState
  } = useAppState();

  const [
    {
      data: { data: businesses, pagination },
      isLoading: businessesLoading,
      error: businessesError
    },
    getBusinesses
  ] = useGetBusinesses({ paginated: true });

  const paginationMode = usePaginationMode();

  const [page, setPage] = useState(1);
  const [limit] = useState(20);
  const [isInfiniteScrollDisabled, setIsInfiniteScrollDisabled] = useState(false);
  const [query, setQuery] = useState<string>(history.action === 'POP'
    ? appState.businesses.searchQuery
    : ''
  );
  const [usePreviousBusinessesData, setUsePreviousBusinessesData] = useState(history.action === 'POP');

  useEffect(() => {
    if (!usePreviousBusinessesData) {
      setIsInfiniteScrollDisabled(false);
      setPage(1);
      appStateDispatch(clearBusinesses());
      appStateDispatch(setBusinessesSearchQuery(query));
      getBusinesses({
        refresh: true,
        params: {
          page: 1,
          limit,
          query
        }
      });
    } else {
      const defaultPage = pagination?.page || 1;
      setPage(defaultPage);
      setUsePreviousBusinessesData(false);
      getBusinesses({
        params: {
          page: defaultPage,
          limit,
          query
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const changePage = (newPage: number) => {
    appStateDispatch(clearBusinesses());
    setPage(newPage);
    getBusinesses({
      refresh: true,
      params: {
        page: newPage,
        limit,
        query
      }
    });
  }

  const changePageInfinite = (event: any) =>{
    setPage(page + 1);
    setTimeout(() => {
      getBusinesses({
        refresh: true,
        params: {
          page: page + 1,
          limit,
          query
      }
      });
      event.target.complete();
    }, 500);

    if ((businesses?.length || 0) >= pagination?.total) {
      setIsInfiniteScrollDisabled(true);
    }
  }

  const generateBusinessesList = () => businesses?.map(business => (
    <BusinessItem
      key={business.id}
      business={business}
      isLoading={businessesLoading}
    />
  ));

  const updateSearchQuery = (e: any) => {
    const value = e.detail.value || '';
    setQuery(value.trim());
  };

  return (
    <IonContainer>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton data-testid="menu-button"></IonMenuButton>
          </IonButtons>
          <IonTitle>Businesses</IonTitle>
        </IonToolbar>
      </IonHeader>

      <SearchContainer>
        <SearchBar
          data-testid="search-input"
          value={query}
          onIonChange={updateSearchQuery}
          debounce={1000}
        />
      </SearchContainer>

      {businesses && (
        <IonContent>
          <BusinessesListcontainer>
            {generateBusinessesList()}
          </BusinessesListcontainer>

          {
            paginationMode === 'pagination'
              ? <PaginationArrows
                changePage={changePage}
                page={page}
                pagination={pagination}
              />
              : <InfiniteScroll
                changePage={changePageInfinite}
                disabled={isInfiniteScrollDisabled}
              />
          }
        </IonContent>
      )}

      {
        businessesLoading && (
          <Centered>
            <IonSpinner data-testid="page-spinner" title="page-spinner" name="crescent" />
          </Centered>
        )
      }

      <IonAlert
        isOpen={!!businessesError}
        data-testid="req-error-message"
        cssClass="modal-dark-mode"
        header="Network Error"
        message="There was an error processing your request. Please try again later."
        buttons={['OK']}
      />
    </IonContainer>
  );
};

export default Businesses;
