import {
  IonAlert,
  IonHeader,
  IonButtons,
  IonMenuButton,
  IonSpinner,
  IonTitle,
  IonToolbar,
  getPlatforms
} from '@ionic/react';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import {
  useGetConsumers
} from '../../../features/consumers/consumers-resolver'
import {useAppState} from '../../../shared/AppContext'
import {Centered} from '../../../shared/StyledComponents'
import { Panel } from '../../../features/consumers/consumers-types';
import { VerticalLayout } from '../../../features/consumers/consumers-styles'
import {clearConsumers} from '../../../features/consumers/consumers-actions'
import ConsumerItems from './ConsumerItems'
import PaginatedPage from '../../../components/paginatedPage'

// SearchBarInfo
import {
  SearchContainer,
  SearchBar,
} from '../../../components/SearchBarContainerAndInfo';

// -----------------------
export const ConsumersListcontainer = styled.div.attrs({
  className: 'ion-padding'
})`
  display: flex;
  flex-direction: column;

  margin: 0 10px;
  gap: 25px;
`;



function Header(){
  return <IonHeader>
    <IonToolbar>
      <IonButtons slot='start'>
        <IonMenuButton data-testid='menu-button'>
        </IonMenuButton>
      </IonButtons>
      <IonTitle>Consumers</IonTitle>
    </IonToolbar>
  </IonHeader>
}


export default function Consumers(){
  const {dispatch: appStateDispatch} = useAppState()
  const [query, setQuery] = useState('')
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(20)
  const [panel, setPanel] = useState<Panel>('consumers')
  const [
    isInfiniteScrollDisabled,
    setIsInfiniteScrollDisabled
  ] = useState(false)
  const [queryMethod, setQueryMethod] = (
    useState<'infinite' | 'pagination'>('infinite')
  )
  const [
    {
      isLoading,
      error,
      data: {pagination, data: consumers}
    },
    getConsumers
  ] = useGetConsumers({paginated: true})

  useEffect(()=>{
    const platforms = getPlatforms()
    if (platforms.length === 1  && platforms[0] === 'desktop'){
      setQueryMethod('pagination')
    }
  }, [])

  useEffect(()=>{
    setIsInfiniteScrollDisabled(false)
    setPage(1)
    appStateDispatch(clearConsumers())
    getConsumers({
      refresh: true,
      params: {page: 1, limit, query}
    })
  }, [query])

  const changePage = (newPage: number) => {
    appStateDispatch(clearConsumers())
    setPage(newPage)
    getConsumers({
      refresh: true,
      params: {page:newPage, limit, query}
    })
  }

  const changePageInfinite = (event: any) =>{
    setPage(page + 1)
    setTimeout(()=>{
      getConsumers({
        refresh: true,
        params: {
          page: page + 1,
          limit,
          query
        }
      })
      event.target.complete();
    }, 500)

    const csmrs = consumers?.length || []
    if(csmrs >= pagination?.total){
      setIsInfiniteScrollDisabled(true)
    }
  }

  return <PaginatedPage
      header={<Header/>}
      page={page}
      pagination={pagination}
      changePagePagination={changePage}
      changePageInfinite={changePageInfinite}
      isInfiniteScrollDisabled={isInfiniteScrollDisabled}
    >
      <VerticalLayout>
      <SearchContainer>
        <SearchBar
          value={query}
          onIonChange={(e)=>{setQuery(e.detail.value!)}}
          debounce={1000}
        />
      </SearchContainer>
      <ConsumersListcontainer>
        <ConsumerItems
          consumers={consumers ? consumers : []}
          changePanel={(newPanel)=>{setPanel(newPanel)}}
          panel={panel}
        />
      </ConsumersListcontainer>
    {
      isLoading ?
      <Centered>
        <IonSpinner
          data-testid="page-spinner"
          title="page-spinner"
          name="crescent"
        />
      </Centered>
        : null
    }
    </VerticalLayout>
    <IonAlert
    isOpen={error ? true : false }
    cssClass="modal-dark-mode"
    header="Network Error"
    buttons={["OK"]}
    message={
      "There was an error processing your request. " +
      "Please try again later."
    }
      />
  </PaginatedPage>
}
