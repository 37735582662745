import React from "react";
import { BusinessPromoCode } from "../../../../../features/business-promo-codes/business-promo-codes-types";
import {
  Item,
  IconHeader,
  Column,
  ItemText,
  TextLabel,
} from '../../../../../components/StylesListing';

// Icons
import { IonIcon } from '@ionic/react';
import { openOutline } from 'ionicons/icons';

interface PromoCodeItemProps {
  promoCode: BusinessPromoCode;
  isLoading: boolean;
}

export const PromoCodeItem: React.FC<PromoCodeItemProps> = ({
  promoCode: {
    id,
    name,
    value,
  },
  isLoading,
}) => {
  return (
    <>
      <Item
        data-testid="promo-code-item"
        disabled={isLoading}
        button
        routerLink={`/businesses/promo-codes/${id}`}
      >
        <IconHeader>
          <IonIcon icon={openOutline} />
        </IconHeader>
        <Column>
          <ItemText data-testid="promo-code-item-id" isTitle>
            <TextLabel>ID: </TextLabel> {id}
          </ItemText>
          <ItemText data-testid="promo-code-item-name">
            <TextLabel>Name: </TextLabel> {name}
          </ItemText>
          <ItemText data-testid="promo-code-item-value">
            <TextLabel>Value: </TextLabel> ${value}
          </ItemText>
        </Column>
      </Item>
    </>
  )
};
