import styled from "styled-components";
import { IonCard, IonCol } from '@ionic/react';
import { darken } from 'polished';

export const IconHeader = styled.div`
  padding: 10px 15px 0 0;
  position: absolute;
  right: 0;
  margin: 0 !important;
  display: flimport important from '../../../../../../node_modules/polished/lib/helpers/important.d';
  justify-content: end;

  font-size: 18px;
  color: white;
`;

export const Item = styled(IonCard)`
  --background: var(--plink-blue);
  width: 100%;
  margin: 0 auto;
  padding: 5px 20px;
  border: .7px solid white;
  border-radius: 10px;
  transition: all .2s ease-in-out;

  &:hover {
    --background: ${darken(0.1, '#334bc1')};
    border: .7px solid var(--plink-blue);
  }
`;

export const Column = styled(IonCol)`
  margin: 10px;
`;


interface ItemTextI {
  bold?: boolean
  isTitle?: boolean
  italic?: boolean
  footerRight?: boolean
}


export const ItemText = styled.p<ItemTextI>`
  padding: 0px;
  margin: 5px 0;
  overflow-wrap: break-word;

  font-size: 14px;
  color: white;

  font-weight: ${(props) => props.bold ? 700 : 400};
  font-style: ${(props) => props.italic ? 'italic' : 'normal'};
  text-transform: ${(props) => props.isTitle ? 'uppercase' : 'default'};
  letter-spacing: ${(props) => (props.isTitle || props.footerRight) ? '0.8px' : 'default'};
  font-family: ${(props) => props.isTitle ? 'Montserrat' : 'Open Sans'}, serif;
`;

export const TextLabel = styled.span`
  font-weight: bold;
  font-family: 'Montserrat', serif;
`;

export const FooterRight = styled.div`
  text-align: end;
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 10px 15px 20px 0;

  font-style: italic;
  color: white;
  font-family: 'Open Sans', serif;
  font-size: 12px;
`
