import React from "react";
import { Business } from "../../../../../features/businesses/businesses-types";
import { format, parseISO } from "date-fns";

import {
  Item,
  Column,
  IconHeader,
  ItemText,
  TextLabel,
  FooterRight,
} from '../../../../../components/StylesListing';

// Icons
import { IonIcon } from '@ionic/react';
import { openOutline } from 'ionicons/icons';

interface BusinessItemProps {
  business: Business;
  isLoading: boolean;
}

export const BusinessItem: React.FC<BusinessItemProps> = ({
  business: {
    id,
    businessName,
    contactEmail,
    contactFirstName,
    contactLastName,
    createdAt,
  }
}) => {
  const fullNameResult = contactLastName && contactFirstName
    ? `${contactFirstName} ${contactLastName}`
    : contactLastName || contactFirstName || 'N/A';

  const formattedRegistrationDate = format(parseISO(createdAt), 'MM/dd/yyyy');

  return (
    <>
      <Item
        data-testid="business-item"
        button
        routerLink={`/businesses/${id}`}
      >
        <IconHeader>
          <IonIcon icon={openOutline} />
        </IconHeader>
        <Column>
          <ItemText data-testid="business-item-id">
            <TextLabel>ID: </TextLabel>{id}
          </ItemText>
          <ItemText data-testid="business-item-business-name">
            <TextLabel>Business Name: </TextLabel>{businessName.toUpperCase()}
          </ItemText>
          <ItemText data-testid="business-item-contact-full-name">
            <TextLabel>Business Full Name: </TextLabel>{fullNameResult}
          </ItemText>
          <ItemText data-testid="business-item-contact-email">
          <TextLabel>Contact Email:</TextLabel> {contactEmail}
          </ItemText>
        </Column>
        <FooterRight data-testid="business-item-registration-date">
          Created at: {formattedRegistrationDate}
        </FooterRight>
      </Item>
    </>
  )
};
