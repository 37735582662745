import { ReactNode } from 'react';
import styled from 'styled-components';
import {Consumer } from '../../../../features/consumers/consumers-types';
import {format, parseISO} from 'date-fns';
import Labels from '../Labels'

// Icons
import { IonIcon } from '@ionic/react';
import { openOutline } from 'ionicons/icons';

// Styles for Listing
import {
  Item as ConsumerItem,
  Column,
  IconHeader,
} from '../../../../components/StylesListing';

// ---------------------------------

const MainFields = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  &:hover{
    cursor: pointer;
    color: var(--ion-color-secondary);
  }
`

const formatDate = (dte:string)=>{
  return format(parseISO(dte), 'MM/dd/yyyy')
}

type ItemProps = {
  consumer: Consumer
  children?: ReactNode
}

export default function Item(props: ItemProps){
  const { consumer } = props


  return <>
    <ConsumerItem
      button
      routerLink={`/consumer/${consumer.consumerId}/details`}
      data-testid='consumer-item'
      className='customer-item'
    >
      <IconHeader>
        <IonIcon icon={openOutline} />
      </IconHeader>
      <Column>
        <MainFields className='main-fields'>
          <Labels
            consumer={consumer}
            fields={[
              'consumerId',
              'bankAccountId',
              'email',
            ]}
            useFullName={false}
          />
        </MainFields>
      </Column>
    </ConsumerItem>
  </>
}
