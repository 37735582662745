import styled from "styled-components";

export const Centered = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`;

export const NoDataText = styled.p`
  padding: 0px;
  margin: 20px 10px 10px 25px;
  margin-left: 25px;
  overflow-wrap: break-word;
  font-size: 20px;
`;


export const BusinessesEntitiesListcontainer = styled.div.attrs({
  className: 'ion-padding'
})`
  display: flex;
  flex-direction: column;

  margin: 0 10px;
  gap: 25px;
`;
